let resumeData = {
    "imagebaseurl":"https://github.com/aminbassim",
    "name": "Amín Bassim",
    "role": "Fullstack Developer",
    "email":"hi@aminbassim.com",
    "skypeid": "skypeid",
    "roleDescription": " I like dabbling in various parts of frontend and backend development and like to learn about new technologies, read books and travel. I love to practice Kung Fu and gymnastics in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/aminbassim",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"http://twitter.com/",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I started my journey by studying microcomputer systems and networks, then delved into web applications development. Currently, I am employed as a developer while pursuing a degree in Computer Engineering at U.N.E.D. I am currently delving deeper into modern frameworks for software development. I firmly believe that to succeed in life, one must keep their goals in sight and continually work towards them.",
    "address":"Spain",
    "website":"hi@aminbassim.com",
    "education":[
      {
        "UniversityName":"U.N.E.D",
        "specialization":"Computer Engineering",
        "MonthOfPassing":"Oct",
        "YearOfPassing":"2021",
        "Achievements":""
      },
      {
        "UniversityName":"I.E.S Virrey Morcillo",
        "specialization":"Web Applications Development",
        "MonthOfPassing":"Sep - 2019",
        "YearOfPassing":"Jun - 2021",
        "Achievements":""
      },
      {
        "UniversityName":"I.E.S Dr Alarcón Santón",
        "specialization":"Microcomputer systems and networks",
        "MonthOfPassing":"Sep - 2013",
        "YearOfPassing":"Jun - 2015",
        "Achievements":""
      }      
    ],
    "work":[  
      /* {
        "CompanyName":"Capgemini",
        "specialization":"Fullstack Developer",
        "MonthOfLeaving":"Jul",
        "YearOfLeaving":"2022",
        "Achievements":"Java, Java EE, Hibernate, Spring, JavaScript, SQL"
      }, */  
      {
        "CompanyName":"Sopra Steria, S.A.",
        "specialization":"Software engineer",
        "MonthOfLeaving":"JuL",
        "YearOfLeaving":"2022",
        "Achievements":"Java, Spring Boot, Angular, Oracle SQL, DevOps, Automation Testing"
      },        
      {
        "CompanyName":"Informática24, S.L.",
        "specialization":"Fullstack developer",
        "MonthOfLeaving":"Apr",
        "YearOfLeaving":"2021",
        "Achievements":"Laravel, PHP, JavaScript, SQL, Azure, TensorFlow, Linux"
      }      
    ],
    "hardSkillsDescription":"Hard Skills",
    "hardSkills":[
      {
        "skillname":"Java"
      },
      {
        "skillname":"Spring Boot"
      },
      {
        "skillname":"Automation Testing"
      },
      {
        "skillname":"Cloud"
      },
      {
        "skillname":"PHP"
      },
      {
        "skillname":"JavaScript"
      },
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS3 - SASS"
      },
      {
        "skillname":"Angular"
      },      
      {
        "skillname":"PL/SQL"
      },
      {
        "skillname":"Linux"
      }      
    ],
    "softSkillsDescription":"Soft Skills",
    "softSkills":[
      {
        "skillname":"Teamwork and good communication"
      },
      {
        "skillname":"Transparency and cooperation"
      },
      {
        "skillname":"Flexibility and adaptability"
      },
      {
        "skillname":"Managing stress and working under pressure"
      },
      {
        "skillname":"Learning and dedication"
      },
      {
        "skillname":"Empathy"
      }   
    ],    
    "portfolio":[
      {
        "name":"Origami",
        "description":"Mobile App",
        "imgurl":"images/portfolio/origami.jpg"
      },
      {
        "name":"Judah",
        "description":"Web App",
        "imgurl":"images/portfolio/judah.jpg"
      },
      {
        "name":"Farmer Boy",
        "description":"Mobile App",  
        "imgurl":"images/portfolio/farmerboy.jpg"
      },
      {
        "name":"RetroCam",
        "description":"Web App",
        "imgurl":"images/portfolio/retrocam.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData